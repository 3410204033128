import { Accordion, Text } from '@mantine/core';
import { IconCheckupList, IconCircleLetterN } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import CheckList from '../check-list/check-list';

import styles from './common-form-info.module.css';

import { CommonNitrogenForm, formatDate } from '@data-access';

interface CommonFormInfoProps {
  form: CommonNitrogenForm;
  withItemsIcon?: boolean;
}

export function CommonFormInfo({
  form,
  withItemsIcon = true,
}: CommonFormInfoProps) {
  const { t } = useTranslation();

  const agronomicList = [
    {
      value: form.variety?.name,
      label: t('common.variety'),
    },
    {
      value: formatDate({
        format: 'display',
        date: form.seed_date,
      }),
      label: t('common.seed_date'),
    },
    {
      value: t(`common.properties.yield_potential.${form.yield_potential}`),
      label: t('common.yield_potential'),
      unit: 'q/ha',
    },
    {
      value: form.olympic_yield,
      label: t('common.olympic_yield'),
      unit: 'q/ha',
    },
    {
      value: form.X_dose,
      label: t('common.X_dose'),
      unit: 'u/ha',
    },
  ];

  const fertList = [
    {
      value: form.nitrogen_already_applied,
      label: t('common.nitrogen_already_applied'),
      unit: 'u/ha',
      optional: true,
    },
    {
      value: form.product?.name,
      label: t('common.product'),
    },
  ];

  const accordion = [
    {
      title: t('advices.agronomic_data'),
      items: agronomicList,
      icon: <IconCheckupList />,
    },
    {
      title: t('advices.fertilizing'),
      items: fertList,
      icon: <IconCircleLetterN />,
    },
  ];
  return (
    <Accordion defaultValue={accordion.map(({ title }) => title)} multiple>
      {accordion.map(({ title, items, icon }) => (
        <Accordion.Item key={title} value={title}>
          <Accordion.Control icon={icon}>
            <Text fw="bold" size="lg">
              {title}
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <CheckList items={items} withIcon={withItemsIcon} />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default CommonFormInfo;
