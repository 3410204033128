import {
  Stack,
  Paper,
  Box,
  Checkbox,
  Group,
  Button,
  Slider,
  Select,
  Text,
  Input,
  Title,
  NumberInput,
} from '@mantine/core';
import { useForm, TransformedValues } from '@mantine/form';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useParams, useRouter } from '@tanstack/react-router';
import { featureCollection } from '@turf/turf';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Source, Layer } from 'react-map-gl';

import classes from './page.module.css';

import { advicesApi, useAdvicesQueries } from '@advices/data-access';
import { layersAdminOptions, useHandleNavBack } from '@data-access';
import { MAP_TYPES, useFieldsQueries } from '@fields/data-access';
import {
  PfMap,
  PfMapProps,
  PLOTS_LAYER_ID,
  PlotsLayer,
  SelectImageSlider,
  zoomOnFeature,
} from '@map';
import { FullMainHeight, FieldHeader } from '@ui';

export function CommonAdviceAdminPage() {
  const { t } = useTranslation();
  const { resultId, yieldId, type } = useParams({
    from: '/_app/precifert-n_/$type/results/$resultId_/settings/$yieldId',
  });
  const handleBack = useHandleNavBack({
    defaultNavigation: {
      to: '/precifert-n/$type/results/$resultId',
      params: {
        resultId,
        yieldId,
        type,
      },
    },
  });
  const { navigate } = useRouter();
  

  const [heatmapType, setHeatmapType] = useState('RGB');
  const [rasterOpacity, setRasterOpacity] = useState(100);
  const [rasterOpacityEnd, setRasterOpacityEnd] = useState(100);
  const queries = useAdvicesQueries();
  const fieldsQueries = useFieldsQueries();

  const advice = useSuspenseQuery(
    queries.result({
      id: Number(resultId),
      crop: type,
    }),
  );
  const field = useSuspenseQuery(fieldsQueries.detail(advice.data.field.id));

  const { data } = useSuspenseQuery(
    queries.commonAdmin({
      fieldId: field.data.id,
      yieldId: Number(yieldId),
      layer: heatmapType,
    }),
  );
  const values = useQuery(queries.commonAdminValues());

  const yieldDate = dayjs(
    advice.data.yields.find((y) => y.id === Number(yieldId))?.date,
  ).format('DD/MM');

  const updateSettings = useMutation({
    mutationFn: advicesApi.updateCommonSettings,
  });

  const initialSelected = useMemo(
    () =>
      data?.data
        .filter((heatmap) => !heatmap.clouds)
        .map((heatmap) => heatmap.id.toString()) || [],
    [data?.data],
  );

  const form = useForm({
    initialValues: {
      is_hidden: data.is_hidden,
      disable_mail: data.disable_mail,
      
      correlation_coeff: data.correlation_coeff,
      inverted: data.inverted,
      force_inversion: data.force_inversion,
      force_non_inversion: data.force_non_inversion,

      is_absurd: data.is_absurd,
      force_absurd_yield: data.force_absurd_yield,

      one_date: data.one_date,
      use_landsat : data.use_landsat,

      data_collection: data.data_collection,

      // apply_to_farm: data.apply_to_farm;
      // apply_to_dates: data.apply_to_dates;

      selectedHeatmaps: initialSelected || [],
      selectedHeatmap: initialSelected[0] || null,
    },
    transformValues(v) {
      const { selectedHeatmaps, selectedHeatmap, ...rest } = v;

      const add = selectedHeatmaps
        .filter((id) => !initialSelected?.includes(id))
        .map((id) => Number(id));

      const d = initialSelected
        ?.filter((id) => !selectedHeatmaps.includes(id))
        .map((id) => Number(id));

      const draft = {
        ...rest,
        add,
        delete: d,
      };

      return draft;
    },
  });

  const heatmapData = data?.data.find(
    (heatmap) => heatmap.id.toString() === form.getValues().selectedHeatmap,
  );

  const handleMapLoad: PfMapProps['onLoad'] = (map) => {
    zoomOnFeature({
      geojson: field.data,
      map: map.target,
    });
  };

  const formatUrl = (url: string) => {
    // rreplace the BBOX search parameter with {bbox-epsg-3857}
    const bbox = 'bbox-value';
    const urlObject = new URL(url);
    urlObject.searchParams.set('BBOX', bbox);
    return urlObject.href.replace(bbox, '{bbox-epsg-3857}');
  };

  const handleHeatmapChange = (value: string) => {
    form.setFieldValue('selectedHeatmap', value);
  };

  const handleHeatmapsChange = (value: string[]) => {
    form.setFieldValue('selectedHeatmaps', value);
  };

  type Transformed = TransformedValues<typeof form>;

  const handleSubmit = (v: Transformed) => {
    updateSettings.mutate({
      fieldId: field.data.id,
      yieldId: Number(yieldId),
      ...v,
    },
    {
      onSuccess: () => {
        navigate({
          to: '/precifert-n/$type/results/$resultId',
          params: {
            resultId,
            type,
          },
        })
    },
  });
  };

  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
      <FullMainHeight.Sidebar size="lg">
  <Stack className={classes.navCol}>
    <Paper className={classes.navHeader}>
      <FieldHeader fieldId={advice.data.field.id.toString()} />
    </Paper>
    <Paper className={classes.navBar}>
      <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
        <Box>
          <Checkbox
              {...form.getInputProps('one_date', { type: 'checkbox' })}
              label="Calculer en One Date"
              labelPosition="left"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
          <Checkbox
              {...form.getInputProps('use_landsat', { type: 'checkbox' })}
              label="Calculer en Landsat"
              labelPosition="left"
              mt="md"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
            <Checkbox
              {...form.getInputProps('disable_mail', { type: 'checkbox' })}
              label="Bloquer l'envoi de mail"
              labelPosition="left"
              mt="md"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
            <Checkbox
              {...form.getInputProps('is_hidden', { type: 'checkbox' })}
              label="Masquer le conseil"
              labelPosition="left"
              mt="md"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
            <Checkbox
              {...form.getInputProps('apply_to_dates', { type: 'checkbox' })}
              label="Appliquer à toutes les dates"
              labelPosition="left"
              mt="md"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
            <Checkbox
              {...form.getInputProps('apply_to_farm', { type: 'checkbox' })}
              label="Appliquer à la ferme"
              labelPosition="left"
              mt="md"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
          {/* Paramètres d'Inversion */}
          <Box mt="md">
            <Text mb="xs" size="xl">
              Paramètres d'Inversion
            </Text>
              <Box mt="md">
              <Text mb="xs" size="md">
              Coefficient de corrélation
            </Text>
            <Text size="md">
              {form.values.correlation_coeff} {/* Display the correlation coefficient */}
            </Text>
          </Box>
            <Checkbox
              {...form.getInputProps('inverted', { type: 'checkbox' })}
              disabled
              label="Parcelle inversée"
              labelPosition="left"
              mt="md"
              onClick={(e) => e.preventDefault()}
              size="md"
              styles={{
                body: { justifyContent: 'space-between' },
                label: { color: 'black' }, // Ajustement de style pour l'indicateur
              }}
              w="80%"
            />
            <Checkbox
              {...form.getInputProps('force_inversion', { type: 'checkbox' })}
              label="Forcer l'inversion"
              labelPosition="left"
              mt="md"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
            <Checkbox
              {...form.getInputProps('force_non_inversion', { type: 'checkbox' })}
              label="Forcer sans inversion"
              labelPosition="left"
              mt="md"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
          </Box>

          {/* Paramètres de Rendement */}
          <Box mt="md">
            <Text mb="xs" size="xl" >
              Paramètres de Rendement
            </Text>
            <Checkbox
              {...form.getInputProps('is_absurd', { type: 'checkbox' })}
              disabled
              label="Rendement absurde"
              labelPosition="left"
              mt="md"
              onClick={(e) => e.preventDefault()}
              size="md"
              styles={{
                body: { justifyContent: 'space-between' },
                label: { color: 'black' },
              }}
              w="80%"
            />
            <Checkbox
              {...form.getInputProps('force_absurd_yield', { type: 'checkbox' })}
              label="Forcer rendement absurde"
              labelPosition="left"
              mt="md"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
            <Checkbox
              {...form.getInputProps('is_absurd_evolution', { type: 'checkbox' })}
              disabled
              label="Évolution de rendement absurde"
              labelPosition="left"
              mt="md"
              onClick={(e) => e.preventDefault()}
              size="md"
              styles={{
                body: { justifyContent: 'space-between' },
                label: { color: 'black' },
              }}
              w="80%"
            />
            <Checkbox
              {...form.getInputProps('force_absurd_yield_evolution', { type: 'checkbox' })}
              label="Forcer évolution rendement absurde"
              labelPosition="left"
              mt="md"
              size="md"
              styles={{ body: { justifyContent: 'space-between' } }}
              w="80%"
            />
          </Box>

          {/* Source d'image
          <Group gap={0} mt="md" w="80%" wrap="nowrap">
            <Input.Label flex="1 1 auto" htmlFor="data_collection" miw={1} size="md">
              Source d'image
            </Input.Label>
            <Select
              {...form.getInputProps('data_collection')}
              data={values.data?.data_collection}
              flex="1 0 50px"
              id="data_collection"
            />
          </Group> */}
        </Box>
        <Group>
          <Button type="submit">Enregistrer</Button>
          <Button color="red" onClick={handleBack} type="button">
            Retour
          </Button>
        </Group>
      </form>
    </Paper>
  </Stack>
</FullMainHeight.Sidebar>

        <Stack h="100%" style={{ overflow: 'hidden' }}>
          <Paper>
            <Group justify="space-between">
              <Title order={2}>Potentiel de rendement du {yieldDate}</Title>
              <Group>
                <Box miw={250}>
                  <Text size="md">Opacité de la carte</Text>
                  <Slider
                    marks={[{ value: 20 }, { value: 50 }, { value: 80 }]}
                    onChange={setRasterOpacity}
                    onChangeEnd={setRasterOpacityEnd}
                    value={rasterOpacity}
                  />
                </Box>
                <Select
                  data={layersAdminOptions(t)}
                  onChange={(v) => setHeatmapType(v ?? 'LAI')}
                  value={heatmapType}
                />
              </Group>
            </Group>
          </Paper>
          <Paper h="100%" p={0}>
            <Stack gap={0} h="100%">
              <PfMap onLoad={handleMapLoad}>
                {heatmapData ? (
                  <Source
                    bounds={data?.maxbounds}
                    id="wms-sources"
                    minZoom={14}
                    tileSize={256}
                    tiles={[formatUrl(heatmapData.url)]}
                    type="raster"
                  >
                    <Layer
                      beforeId={`${PLOTS_LAYER_ID}_outline`}
                      id="wms-layer"
                      minzoom={12}
                      paint={{
                        'raster-opacity': rasterOpacityEnd / 100,
                        'raster-opacity-transition': { duration: 0 },
                      }}
                      source="wms-sources"
                      type="raster"
                    />
                  </Source>
                ) : null}
                <PlotsLayer data={featureCollection([field.data])} outline />
              </PfMap>
              <SelectImageSlider
                key={heatmapType + data?.data?.length}
                multiSelect={false}
                onChange={handleHeatmapChange}
                onSubValueChange={handleHeatmapsChange}
                options={
                  data?.data.map((heatmap) => ({
                    value: heatmap.id.toString(),
                    src: heatmap.thumbnail,
                    valueLabel: heatmap.date,
                    manual_check : heatmap.manual_check
                  })) || []
                }
                subValue={form.getValues().selectedHeatmaps}
                value={form.getValues().selectedHeatmap || ''}
              />
            </Stack>
          </Paper>
        </Stack>
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
}

export default CommonAdviceAdminPage;
