import { isNotEmpty, useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';

import { ProcessYieldMapDto } from 'modules/fields/data-access/src/lib/types/dto';

interface YieldMapFormValues {
  map_id?: number;
  name?: string;
  column_name: string;
  species: string;
}

// column_name = request.data.get('column_name')
// species = request.data.get('species')
// name = request.data.get('name',None)
// map_id = request.data.get('map_id')

type TransformValues = (values: YieldMapFormValues) => ProcessYieldMapDto;

export const useYieldMapForm = (fieldId: number) => {
  const { t } = useTranslation();

  return useForm<YieldMapFormValues, TransformValues>({
    initialValues: {
      column_name: '',
      name: '',
      species: '',
    },
    validate: {
      map_id: isNotEmpty(t('form.required')),
      column_name: isNotEmpty(t('form.required')),
      species: isNotEmpty(t('form.required')),
      name: isNotEmpty(t('form.required')),
    },
    transformValues: (values) => {
      return {
        ...values,
        map_id: values.map_id!,
        species: Number(values.species),
      };
    },
  });
};
